import React from "react";
import Layout from "../components/common/Layout";
import HeroSection from "../components/common/HeroSection";
import ProjectCard from "../components/projects/ProjectCard";
import { ongoingdata } from "../components/projects/commercial/OngoingData";

function OngoingProjectsPage() {
  const projects = ongoingdata;

  return (
    <Layout>
      <HeroSection
        title="Commercial Ongoing Projects"
        backgroundImage={
          projects[0] ? projects[0].banner.childImageSharp.fluid.src : null
        }
      />
      <section className="section-pad border-bottom">
        <div className="container">
          <div className="row  justify-content-center">
            {projects.map((data) => (
              <div className="col-lg-4 mb-3" key={data.id}>
                <ProjectCard {...data} />
              </div>
            ))}
            {/* <div className="col-lg-4 mb-3" key={0}>
                <ProjectCard {...node} />
              </div> */}
          </div>
        </div>
      </section>
    </Layout>
  );
}

export default OngoingProjectsPage;
